export enum Source {
  All = "all",
  RSS = "rss",
  Telegram = "telegram",
}

export interface IChannel {
  id: string;
  title: string;
  theme: string;
  link: string;
  type?: Source;
  members_count: number;
}

export interface IMessageExtraInfo {
  author: string;
  link: string;
  title: string;
}

export interface IMessage {
  id: string;
  text: string;
  channel: IChannel;
  match_string: string;
  publish_datetime: string;
  extra: IMessageExtraInfo | null;
}
