import { useContext, useEffect, useMemo, useState } from "react";
import {
  // GridCellParams,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { IChannel, Source } from "../../types";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
// import clsx from "clsx";
import Dayjs from "dayjs";
import { Link } from "react-router-dom";
import { UserContext } from "../../context";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import {
  FirstContainerSC,
  GridContainerWrapperSC,
  SecondContainerSC,
} from "./styled";

import './dash.css'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const getDate = (date: Date) =>
  Dayjs(date).add(3, "hour").format("HH:mm:ss DD/MM/YYYY");

export const generateLinkToMessage = (inner: string, link: string) => (
  <Link
    to={{
      pathname: `/message/${link}`,
    }}
    style={{ color: "inherit", textDecoration: "none" }}
  >
    {inner}
  </Link>
);

console.log()
export const columns: GridColDef[] = [
  {
    headerName: "ID",
    field: "id",
    width: 300,
    renderCell: (params) => generateLinkToMessage(params.row.id, params.row.id),
    disableColumnMenu: true,
    sortable: false,
  },
  {
    headerName: "Тема",
    field: "theme",
    width: 300,
    renderCell: (params) => params.row.channel.theme,
    disableColumnMenu: true,
    sortable: false,
  },
  {
    headerName: "Дата",
    field: "publish_datetime",
    width: 200,
    description: "ЧЧ:ММ:СС ДД/ММ/ГГГ",
    disableColumnMenu: true,
    renderCell: (params) => getDate(params.row.publish_datetime),
    sortable: true,
  },
  {
    headerName: "Сообщение",
    description: "Первые 100 символов",
    field: "text",
    flex: 1.5,
    renderCell: (params) =>
      generateLinkToMessage(params.row.text, params.row.id),
    disableColumnMenu: true,
    sortable: false,

    // uncomment to see colored messages
    // cellClassName: (params: GridCellParams) => {
    //   const mood = params.row.mood;

    //   return clsx("message_preview", {
    //     negative: mood < 0,
    //     positive: mood > 0,
    //     neitral: mood === 0,
    //   });
    // },
  },
  {
    headerName: "Источник",
    field: "channel.title",
    description: "Адрес канала",
    flex: 0.5,
    renderCell: (params) => params.row.channel.title,
    disableColumnMenu: true,
    valueGetter: (params) => params.row.channel.title,
    sortComparator: (v1, v2) => v1?.localeCompare(v2),
    sortable: false,

  },
  {
    headerName: "Тип источника",
    field: "source",
    description: "RSS / ТГ-канал",
    flex: 0.3,
    renderCell: (params) =>
      params.row.channel.type === Source.RSS ? "RSS" : "Телеграм",
    disableColumnMenu: true,
    sortable: false,

  },
  {
    headerName: "Подписчики",
    field: "members_count",
    flex: 0.3,
    renderCell: (params) =>
      params.row.channel.type === Source.RSS
        ? ""
        : params.row.channel.members_count,
    disableColumnMenu: true,
    sortable: false,
  },
];

export const formatSourceValue = (ch:any) => {
  if (ch.type === Source.RSS) {
    return `rss: ${ch.title}`;
  }
  return `tg: ${ch.title} (${ch.members_count})`;

}

export const formatSourceValueInd = (ch:any) => {
  if (ch.type === Source.RSS) {
    return `${ch.theme}`;
  }
  return `${ch.theme} (${ch.members_count})`;

}

export const Toolbar = () => {
  const {
    setSource,
    source,
    date,
    setDate,
    searchText,
    setSearchText,
    addresses,
    setAddresses,
    industry,
    setIndustry,
    rssChannels,
    telegramChannels,
  } = useContext(UserContext);

  const [selectAll,setSelectAll] = useState(false)
  const [selectAllInd,setSelectAllInd] = useState(false)

  let channels = useMemo(
    () => [
      ...rssChannels,
      ...telegramChannels,
    ],
    [rssChannels, telegramChannels]
  );

  let industryFilterObj = channels
  let industryFilter = industryFilterObj.reduce((accumulator:IChannel[], current:IChannel) => {
    if (accumulator.findIndex((object:any) => object.theme === current.theme) === -1) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);


  const { token } =
    useContext(UserContext);
   async function Change (event: SelectChangeEvent<typeof addresses>)  {
    const {
      target: { value }
    } = event;
    setAddresses(typeof value === "string" ? value.split(",") : value);

    const chek:any = value[value.length - 1]
    console.log(chek)
    let getObj = channels.find(chan => chan.link === chek) || { id: ""}
    console.log(getObj)

    let getId = getObj.id
    console.log(getId)
    let ids = localStorage.getItem('id')?.split(',') || []
    ids.push(getId)
    window.localStorage.setItem('id', ids.join(','));
    window.dispatchEvent(new Event("storage"));
    console.log(localStorage.getItem('id'))
  };
  const handleCheckAllChange = () => {
    setSelectAll(!selectAll)
    setAddresses(selectAll ? [] : rssChannels.concat(telegramChannels).map((ch)=>ch.link))
  }

  async function ChangeInd (event: SelectChangeEvent<typeof industry>)  {
    const {
      target: { value }
    } = event;
    setIndustry(typeof value === "string" ? value.split(",") : value);

    const chek:any = value[value.length - 1]
    console.log(chek)
    let getObj = channels.filter(chan => chan.theme === chek) || { theme: ""}
    console.log(getObj)
    let getTheme = getObj.map(el => {return el.id})
    console.log(getTheme)
    let themes = localStorage.getItem('theme')?.split(',') || []
    let flag = themes.filter(el => {return getTheme.includes(el)})
    console.log(flag)
    console.log(themes)
    console.log(getTheme)
    //themes.push(getTheme)
    if(!getTheme) {
      console.log(1)
    }
    window.localStorage.setItem('theme', themes.join(','));
    window.dispatchEvent(new Event("storage"));
    console.log(localStorage.getItem('theme'))
  };

  const handleCheckAllChangeInd = () => {
    setSelectAllInd(!selectAllInd)
    setIndustry(selectAllInd ? [] : rssChannels.concat(telegramChannels).map((ch)=>ch.link))
  }

  useEffect(() => {
    localStorage.setItem('count', '100')
  }, [])

  function goMore() {
    let getCount = localStorage.getItem('count')
    let count = Number(getCount) + 100
    console.log(count)
    localStorage.setItem('count', count.toString())
    window.dispatchEvent(new Event("storage"));
    console.log( localStorage.getItem('count'))
  }



  return (
    <GridContainerWrapperSC>
      <GridToolbarContainer>
        <FormControl sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="demo-simple-select-standard-label">
            Тип источника
          </InputLabel>
          <Select
            label="Тип источника"
            value={source}
            onChange={(e) => setSource(e.target.value as Source)}
          >
            <MenuItem value={Source.All}>
              <em>Все</em>
            </MenuItem>
            <MenuItem value={Source.RSS}>RSS</MenuItem>
            <MenuItem value={Source.Telegram}>Телеграм</MenuItem>
          </Select>
        </FormControl>



        <FormControl sx={{ m: 1, width: 400 }}>
          <InputLabel id="demo-multiple-checkbox-label">Источник
          <Checkbox checked={selectAll} onChange={handleCheckAllChange} />
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={addresses}
            onChange={Change}
            input={<OutlinedInput label="Источник" />}
            renderValue={(selected) => channels.filter((ch) => selected.includes(ch.link)).map((ch) => formatSourceValue(ch)).join(",")}
            MenuProps={MenuProps}
          >
            {channels.map((chan, i) => 
              <MenuItem key={i} value={chan.link} id={chan.id}>
                <Checkbox checked={addresses.indexOf(chan.link) > -1} />
                <ListItemText
                  primary={formatSourceValue(chan)}
                  primaryTypographyProps={{ fontSize: "12px" }}
                />
              </MenuItem>
            )}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, width: 400 }}>
          <InputLabel id="demo-multiple-checkbox-label">Отрасли
          <Checkbox checked={selectAllInd} onChange={handleCheckAllChangeInd} />
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={industry}
            onChange={ChangeInd}
            input={<OutlinedInput label="Отрасли" />}
            renderValue={(selected) => channels.filter((ch) => selected.includes(ch.theme)).map((ch) => formatSourceValueInd(ch)).join(",")}
            MenuProps={MenuProps}
          >
            {industryFilter.filter((elem, index, array) => array.indexOf(elem) == index).map((chan, i) => 
              <MenuItem key={i} value={chan.theme} id={chan.id}>
                <Checkbox checked={industry.indexOf(chan.theme) > -1} />
                <ListItemText
                  primary={formatSourceValueInd(chan)}
                  primaryTypographyProps={{ fontSize: "12px" }}
                />
              </MenuItem>
            )}
          </Select>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label="От"
            value={date.date_from}
            maxDate={Dayjs()}
            inputFormat={"DD/MM/YYYY hh:mm:ss"}
            views={["year", "month", "day", "hours", "minutes", "seconds"]}
            ampmInClock={false}
            ampm={false}
            onChange={(date_from) => {
              setDate({
                ...date,
                date_from,
              });
            }}
            renderInput={(params) => (
              <TextField style={{ width: "220px" }} {...params} />
            )}
          />
          <DateTimePicker
            label="До"
            value={date.date_to}
            minDate={date.date_from}
            maxDate={Dayjs()}
            inputFormat={"DD/MM/YYYY hh:mm:ss"}
            views={["year", "month", "day", "hours", "minutes", "seconds"]}
            ampmInClock={false}
            ampm={false}
            onChange={(date_to) => {
              setDate({
                ...date,
                date_to,
              });
            }}
            renderInput={(params) => (
              <TextField style={{ width: "220px" }} {...params} />
            )}
          />
        </LocalizationProvider>
        <TextField
          sx={{ m: 1, minWidth: 200 }}
          label="Поиск"
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
        />
        <button className="more" onClick={goMore}>Показать больше</button>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    </GridContainerWrapperSC>
  );
};
