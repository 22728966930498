import { useContext, useState, useEffect } from "react";
import { DataGrid, GridSortModel } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { columns, Toolbar } from "./utils";
import { UserContext } from "../../context";
import { IMessage, Source } from "../../types";
import { NoRowsOverlay } from "../NoRowsOverlay";
import { LoadingOverlay } from "../LoadingOverlay";
import Dayjs from "dayjs";
import { useChannels } from "../../hooks/useFetch";
import { useNavigate } from "react-router";
import InfoIcon from "@mui/icons-material/Info";

export const Dashboard = () => {



  const { token, source, date, searchText, addresses } =
    useContext(UserContext);
  const [rows, setRows] = useState<IMessage[]>([]);
  const [loading, setLoading] = useState(true);
  const [schedulerPeriod, setSchedulerPeriod] = useState(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "publish_datetime", sort: "desc" },]);

  const navigate = useNavigate();

  const { fetchRssChannels, fetchTelegramChannels } = useChannels();
  

  const fetchMessages = async () => {
    const { date_from, date_to } = date;
    var params:any={size:"100"}
    if (sortModel.length>0)
      params={order_by:sortModel[0].field,order_dir:sortModel[0].sort, ...params}

    if (date_from)
      params={...params,date_from:Dayjs(date_from).toISOString()}
    if(date_to) params={date_to:Dayjs(date_to).toISOString(), ...params}
    if(source!=Source.All) params={channel_type:source.toString(), ...params}

    

    try {
      let response = await fetch(
        `/api/message?`+ new URLSearchParams(params).toString(),
        //${dateFrom}${dateTo}${search_text}${channel_type}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          
        }
      );

      response = await response.json();

      //@ts-ignore
      if (response?.details) {
        navigate("/login");
      }

      if (Array.isArray(response)) {
        setRows(
          response
            .map((r: any) => ({
              ...r,
              publish_datetime: new Date(r.publish_datetime),
            }))
            .filter((r: any) =>
              !addresses.length ? true : addresses.includes(r.channel.link)
            )
        );
      }
    } catch (e) {
      navigate("/");
    }
    setLoading(false);
  };

  const fetchSettings = async () => {
    try {
      let response = await fetch("/api/settings", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      response = await response.json();
      //@ts-ignore
      setSchedulerPeriod(response?.scheduler_period);
    } catch (e) {
      navigate("/");
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchMessages();
    fetchSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source, date, searchText, addresses,sortModel]);

  useEffect(() => {
    fetchRssChannels(setLoading);
    fetchTelegramChannels(setLoading);
  }, [fetchRssChannels, fetchTelegramChannels]);

  var deleteAllMessages = async () => {
    try {
      let response = await fetch("/api/allmessages",{
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },     
      })
      let respdata = await response.json()
    } catch(e) {
      navigate("/")
    }
  }

  useEffect(() => {
    localStorage.removeItem('id')
    localStorage.removeItem('theme')
  }, [])


  window.addEventListener('storage', () => {
    let getSize = localStorage.getItem("count")
    let size = Number(getSize)
    console.log(size)

    let id:any = localStorage.getItem("id")
    let theme:any = localStorage.getItem("theme")
    let general = []
    if ((id) && (theme)) {
      let chek1 = id.split(',')
      let chek2 = theme.split(',')
      general = chek1.filter((el:any) => {return chek2.includes(el)})

      id = general
      console.log(1)
    } else if ((!id) && (theme)) {
      id = theme
    } else if ((!id) && (!theme)) {
      fetch(`/api/message?size=${size}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "aplication/json",
          }
        }
      )
      .then(res => res.json())
      .then(res => {
        console.log(res)
        setRows(res)
      })
      return
    }
    console.log(general)
    console.log(id)
    fetch(`http://localhost:3000/api/message?channel_ids=${id}&size=${size}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "aplication/json",
        }
      }
    )
    .then(res => res.json())
    .then(res => {
      console.log(res)
      setRows(res)
    })
})

  return (
    <Box
      sx={{
        width: "100%",
        // uncomment to see colored messages
        //   "& .negative": {
        //     color: "red",
        //   },
        //   "& .positive": {
        //     color: "green",
        //   },
      }}
    >
      {Boolean(schedulerPeriod) && (
        <p
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <InfoIcon color="info" /> Период опроса каналов: {schedulerPeriod}{" "}
          минут
          <Button variant="outlined" onClick={deleteAllMessages}>Удалить все сообщения</Button>
        </p>
      )}
      <DataGrid
        rows={loading ? [] : rows}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[50]}
        components={{
          Toolbar,
          NoRowsOverlay,
          LoadingOverlay,
        }}
        style={{ minHeight: "500px" }}
        autoHeight
        loading={loading}
        initialState={{
          sorting: {
            sortModel: [{ field: "publish_datetime", sort: "desc" }],
          },
        }}
        onSortModelChange = {(newSortModel) => setSortModel(newSortModel)}
      />
    </Box>
  );
};
